/** @jsx jsx */
import { jsx } from "theme-ui"
import ConvertKitForm from "convertkit-react"
import Integration from "../util/integrations.json"

const config = {
  formId: Integration.convertkit.formId,
  submitText: Integration.convertkit.submitText
    ? Integration.convertkit.submitText
    : "Subscribe",
}

const Convertkit = () => {
  return (
    <div sx={{ ...convertkitStyles }}>
      <h3>
        {Integration.convertkit.Title
          ? Integration.convertkit.Title
          : "Subscribe for the latest updates"}
      </h3>
      <p>
        {Integration.convertkit.subscribeDescription
          ? Integration.convertkit.subscribeDescription
          : "No spam, just weekly (at most) blog posts, resources, and other updates sent to you. You can unsubscribe at any time."}
      </p>
      <ConvertKitForm {...config} />
    </div>
  )
}

export default Convertkit

const convertkitStyles = {
  mb: 7,
  padding: 5,
  borderRadius: "12px",
  bg: "global.subscriptionBgColor",
  h3: {
    fontSize: 4,
    fontWeight: 900,
    m: 0,
    color: "global.headingColor",
  },
  p: {
    fontSize: 2,
    color: "global.textColor",
    m: 0,
    opacity: 0.8,
  },
  input: {
    py: 3,
    px: 3,
    mt: [3, 4],
    mr: 3,
    mb: 0,
    fontFamily: "DM Mono",
    width: ["100%", "100%", "100%", "35%"],
    appearance: "none",
    border: "1px solid transparent",
    borderRadius: "6px",
    bg: "#fff",
    fontSize: 1,
    outline: "none",
  },
  button: {
    backgroundColor: "#35633d",
    color: "#fff",
    appearance: "none",
    fontFamily: "DM Mono",
    fontWeight: "700",
    py: 3,
    px: 4,
    mt: [3, 3, 4],
    fontSize: 1,
    border: "1px solid #35633d",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "#111",
    },
  },
}
