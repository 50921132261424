/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"

import PortfolioCard from "../components/portfolio-card"

export default function PortfolioListHome(props) {
  const data = props.data
  const portfolio = data.edges
    .filter(edge => !!edge.node.frontmatter.date)
    .map(edge => <PortfolioCard key={edge.node.id} data={edge.node} />)
  return <PostMaker data={portfolio} />
}

const PostMaker = ({ data }) => (
  <div sx={portfolioStyles.portfolioContainer}>
    <section sx={portfolioStyles.portfolios}>
      <h2 sx={{ variant: "variants.pageHomeHead.title" }}>Featured Infosec Guides</h2>
      <div>{data.slice(0, 3)}</div>
      {data.length > 3 ? (
        <Link sx={portfolioStyles.moreButton} to="/portfolio">
          See all guides
          <span className="icon">&rarr;</span>
        </Link>
      ) : (
        ""
      )}
    </section>
  </div>
)

const portfolioStyles = {
  portfolioContainer: {
    variant: "variants.section",
  },
  portfolios: {
    variant: "variants.container",
  },
  moreButton: {
    variant: "variants.moreButton",
  },
}
