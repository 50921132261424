/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import slugify from "slugify"

const postPrefix = `/blog/`
const BlogCard = ({ data }) => (
  <article sx={blogStyles.postCard}>
    {data.frontmatter.featuredImage ? (
      <div sx={blogStyles.postImage}>
        <Link to={`/blog/` + data.frontmatter.slug ? data.frontmatter.slug : slugify(`${data.frontmatter.title}`)}>
          <GatsbyImage
            image={
              data.frontmatter.featuredImage.childImageSharp.gatsbyImageData
            }
            alt={data.frontmatter.title + " - Featured image"}
            sx={blogStyles.featuredImage}
          />
        </Link>
      </div>
    ) : (
      ""
    )}
    <div sx={blogStyles.postContent}>
      {data.frontmatter.title ? (
        <h3 sx={blogStyles.title}>
          <Link to={`/blog/` + data.frontmatter.slug ? data.frontmatter.slug : slugify(`${data.frontmatter.title}`)}>
            {data.frontmatter.title}
          </Link>
        </h3>
      ) : (
        ""
      )}
      {data.frontmatter.title ? (
        <div sx={blogStyles.meta}>
          {data.frontmatter.authors === "0" ? (
            ""
          ) : (
            <div sx={{ display: "inline" }}>
              {"by "}
              <Link
                to={`/about`}
                sx={blogStyles.authors}
              >
                <span>{data.frontmatter.authors}</span>
              </Link>
              <span>&nbsp;&bull;&nbsp;</span>
            </div>
          )}
          <time>{data.frontmatter.date}</time>
        </div>
      ) : (
        ""
      )}
    </div>
  </article>
)

export default BlogCard

const blogStyles = {
  featuredImage: {
    maxWidth: "100%",
    width: "100%",
    // border: "2px solid",
    borderColor: "global.borderColor",
    boxShadow: "5px 5px 0px 0px #eee",
  },
  meta: {
    m: 0,
    mb: 2,
    fontSize: 2,
    color: "global.mutedColor",
  },
  authors: {
    fontSize: 2,
    color: "home.headingColor",
    textTransform: "capitalize",
    "&:hover": {
      color: "global.primarylinkColor",
    },
  },
  title: {
    m: "0",
    mt: [3],
    mb: [2],
    fontWeight: "heading",
    fontSize: [4, 4, 4, 4],
    a: {
      color: "home.headingColor",
      "&:hover": {
        color: "global.primarylinkColor",
      },
    },
  },
  description: {
    m: 0,
    mt: 3,
    fontSize: 3,
    color: "global.textColor",
  },
}
